import React, {useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaPrint, FaMinusCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Common/Loader';
import { dateFormatter } from '../../CommonJsFunc/CommonJsFunc';
import FreightInvModalPopup from "./FreightInvModalPopup";
import ConfirmationPopup from '../ConfirmationPopup';

import { Context } from '../../../context/MenuContext';

const FreightInvoices = () =>{
    
    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
    const [loader, setLoader] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [freightInvoice, setFreightInvoice] = useState('');
    const [previewModal, setPreviewModal] = useState(false);
    const [states, setStates] = useState(null);

    const [confirmModalPopup, setConfirmModalPopup] = useState(false);
    const [deletingInvoice, setDeletingInvoice] = useState('');

    const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(Context);

    const getInvoicReports = () => {
        axios
        .get('/api/Lookup/GetFreightInvoices')
        .then((response) => {
            const result = response.data;
            const data = result.map((item) => {
                return {
                    ...item,
                    amountUSD: parseFloat(item.freightInvItems?.map((item) =>  parseFloat(item.amountUSD)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                }
            }) 
            setInvoices(data);
            setLoader(false);
            getStates();
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
            }
            setLoader(false);
        });
    } 
    useEffect(() => {
        setLoader(true);
        getInvoicReports();
        setSelectedMenu('sales');
		setSelectedSubMenu('freightInvoice');
    }, []);

    const getStates = () =>{
        axios
            .get('/api/Lookup/GetStates')
            .then((response) => {
                setStates(response.data);
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
            });
    }

    const handlePreview =(fiNumber)=>{
        const filterData = invoices.filter((e) => e.fiNumber === fiNumber)[0];
        filterData.billingStateName = states[filterData.billingState];
        setFreightInvoice(filterData);
        setPreviewModal(true);
    }  

    const removeInvoice = (invoiceNumber) => {
        if(invoiceNumber !== ''){
          setDeletingInvoice(invoiceNumber);
          setConfirmModalPopup(true);
        }
    };

    const handleRemove = () => {
        setLoader(true);
        setConfirmModalPopup(false);
        
        axios.get(`/api/Lookup/Delete/${deletingInvoice}/FreightInvoice`)
            .then((response) => {
              if(response.data === "success"){
                notify('success', 'Successfully Invoice Removed');
                getInvoicReports();
              }
              else{
                  notify('error', "Something Went Wrong");
                  setLoader(false);
              }
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                    notify('error', e.message);
                    setLoader(false);
                }
            });
    }

    return (
        <>
        <>
        {confirmModalPopup && <ConfirmationPopup invoiceNumber={deletingInvoice} setConfirmModalPopup={setConfirmModalPopup} handleRemove={handleRemove} />}
        {loader ? <Loader /> : '' }
        {previewModal ? <FreightInvModalPopup 
                            invoiceDetails={freightInvoice}
                            setInvoiceDetails={setFreightInvoice}
                            setPreviewModal={setPreviewModal}
                            action='Print'
                        /> : ''}
        <div className="row p-10px item-list respnsive-custom-table">
            <table className='table'>
                <thead className='sticky-header'>
                    <tr className="heading">
                        <th className='bg-body-secondary'>Sl.NO.</th>
                        <th className='bg-body-secondary'>FI No</th>
                        <th className='bg-body-secondary'>Client Name</th>
                        <th className='bg-body-secondary'>FI Date</th>
                        <th className='bg-body-secondary'>Amount USD</th>
                        <th className='bg-body-secondary'>Print</th>
                        <th className='bg-body-secondary'>Delete</th>
                    </tr>
                </thead>
                <tbody>
                { invoices?.map((item, idx) => (
                        <tr className="p-10px item" key={idx}>
                            <td>{idx + 1}</td>
                            <td>    
                                <Link to={`/admin/freightInvoiceForm/${item.fiNumber}`} className='color-b cursor-p'>
                                    {item.fiNumber}
                                </Link> 
                            </td>
                            <td><strong>{item.clientName}</strong></td>
                            <td><span>{item.fiDate}</span></td>
                            <td><span>{item.amountUSD}</span></td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-link m-0 p-0 text-edit"
                                    title="Edit"
                                    onClick={(e) => handlePreview(item.fiNumber)}
                                >
                                    <FaPrint />
                                </button>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-link m-0 p-0 color-r"
                                    title=""
                                    onClick={(e) => removeInvoice(item.fiNumber)}
                                >
                                    <FaMinusCircle />
                                </button>
                            </td>
                    </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
		</>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    </>
    );
}

export default FreightInvoices;