import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import '../styles/custom.css';
import '../styles/invoice.css';
// import '../styles/NewInvoice.css';

import Topbar from '../components/Topbar/Topbar';
import Sidebar from '../components/Sidebar/Sidebar';
import Footer from '../components/Footer/Footer';

import { InvoiceContext } from  '../context/InvoiceContext';

import { AuthContext } from '../context/AuthContext';

const AdminLayout = () => {

    const { user } = useContext(AuthContext);

  return (
    <div id="wrapper" className={user ? '' : 'loginWrapper'}>
        <div className="theme-flex-col">
            <Topbar />
            <div className="theme-flex theme-wrapper">
                {user && <Sidebar /> }
                <div id="content-wrapper" className='container-wrapper'>
                    <InvoiceContext>
                    <main>
                        <Outlet /> {/* This renders the matched child route */}
                    </main>
                    </InvoiceContext>    
                    <Footer />
                </div>
            </div>
        </div>
    </div>
  );
};

export default AdminLayout;
