import React, { useEffect, useContext, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Logo from '../../../images/logo.png';
import QRCode from '../../../images/TGLQRCode.jpeg';
import { Context } from '../../../context/InvoiceContext';
import '../../../styles/print.css';
import { dateFormatter } from '../../CommonJsFunc/CommonJsFunc';
import { RsPaise } from '../../CommonJsFunc/CommonJsFunc';
import './NewInvoice.css';

function InvoicePreview({ setOpenModal, invoice, items, Submit }) {

    // const [title, setTitle] = useState(invoice.invoiceNumber);
    const [, , buttonType] = useContext(Context);
    const componentRef = useRef();
    const totalAmount = items.map((a) => parseFloat(a.total)).reduce((prev, curr) => prev + curr, 0);
    const nonTaxableAmount = items.filter((a) => a.gst === 0).map((a) => parseFloat(a.total)).reduce((prev, curr) => prev + curr, 0);
    const totalRate = items.map((a) => parseFloat(a.rate)).reduce((prev, curr) => prev + curr, 0);
    const tax = items.map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0);
    const data = items.filter((a) => a.itemName !== '');
    var invoiceDate = invoice.invoiceDate === undefined ? new Date() : new Date(invoice.invoiceDate);
    
    const toIndianCurrency = (num) => {
        return parseFloat(num).toFixed(2);
    };
    const GetButtonType = () => {
        switch (buttonType) {
            case 'Save':
            case 'Update':
                return (<button type="submit" onClick={Submit}>{buttonType}</button>);
            case 'Print':
                return <button type="submit" onClick={handlePrint}>{buttonType}</button>;
            default:
            // code block
        }

    }
   
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: invoice.invoiceNumber,
    });
    var netAmount = toIndianCurrency(parseFloat(totalAmount)-parseFloat(!isNaN(invoice.advanceDeposite) ? invoice.advanceDeposite : 0));
    console.log('invoice: ', invoice);
    return (
        <div id="DivIdToPrint" className="new-modal">
        <div className="new-modal-content">
            <div className="titleCloseBtn">
                <button onClick={() => {
                    setOpenModal(false);
                }}>
                    X
                </button>
            </div>
            <div className="invoice-container"  ref={componentRef}>
                <div className="new-invoice-header">
                    <div className="company-logo">
                        <img src={Logo} alt="Company Logo" className="new-logo" />
                    </div>                    
                    <div className="company-details">
                        <h3 className="company-name">TGL EXPRESS INDIA PVT. LTD.</h3>
                        <p className="company-address">Royale Township No.06, Makali New APMC Huskur Main Road</p>
                        <p className="company-address">Nagaruru (V) Dasanapura (H), BENGALURU - 562123</p>
                        <p className="company-contact">GST No. 29AAJCT3309H1Z6 | Email: info@tglexpressindia.com</p>
                    </div>
                    <div className="invoice-qr">
                        <img src={QRCode} alt="Company Logo" className="new-logo m-t-15" />
                    </div>
                </div>
                <div className="new-invoice-header">
                    <div className="new-container">
                        <div className="column left">
                            <p><strong>Invoice Number:</strong><br />
                            {invoice.invoiceNumber}</p>
                        </div>
                        <div className="column center">
                            <h2>Tax Invoice</h2>
                        </div>
                        <div className="column right">
                            <p><strong>Invoice Date:</strong><br/> 
                            {dateFormatter(invoiceDate)}</p>
                        </div>
                    </div>
                </div>
                <div class="customer-container">
                    <div class="customer-info">
                        <h3>Bill To:</h3>
                        <p>M/s. {invoice.clientName}</p>
                        <p>{invoice.clientAddress}</p>
                        <p>{`${invoice.clientAddressLine} ${invoice.stateName}`}</p>
                        <p><strong>GSTIN: {invoice.clientGSTNo}</strong></p>
                        <br/>
                        <br/>
                        <p><strong>Shipper/Consignee:</strong>{invoice.shipper}</p>
                        <p><strong>Country:</strong> {invoice.shipperCountry}</p>
                        <p><strong>Port:</strong> {invoice.shipperPort}</p>
                    </div>
                    <div class="customer-info">
                        <p><strong>SB/BOE NO:</strong> {invoice.sB_BOENo}</p>
                        <p><strong>Dated:</strong> {dateFormatter(invoice.sB_BOENoDate)}</p>
                        <p><strong>MBL/MAWB NO:</strong> {invoice.mbL_MAWBNo}</p>
                        <p><strong>Dated:</strong> {dateFormatter(invoice.mbL_MAWBNoDate)}</p>
                        <p><strong>HBL/HAWB NO:</strong> {invoice.bhL_HAWBNo}</p>
                        <p><strong>Dated:</strong> {dateFormatter(invoice.bhL_HAWBNoDate)}</p>
                        <p>
                            <strong>Package:</strong> {invoice.package}    
                            <strong> | Weight: </strong> {invoice.weight} 
                            <strong> | Ch Wt: </strong> {invoice.chargeableWt}
                        </p>
                        <p><strong>Container No:</strong> {invoice.containerNo}</p>
                        <p><strong>Exchange Rate:</strong> {`${invoice.exchangeCurr}. ${invoice.exchangeRate}`}</p>
                        <p><strong>Invoice No. & DT:</strong> {invoice.shippedInvoiceNo} / {dateFormatter(invoice.shippedInvoiceDate)}</p>
                        <p><strong>Description: </strong> {invoice.invoiceDescription}</p>
                    </div>
                </div>
                <table className="invoice-table t-a-c">
                    <thead>
                        {
                            invoice.stateId == 16 ?
                            (
                            <>
                                <tr>
                                    <th rowSpan="2">Sl. No.</th>
                                    <th rowSpan="2">SAC Code</th>
                                    <th rowSpan="2">Particulars</th>
                                    <th rowSpan="2">Details</th>
                                    <th rowSpan="2">Amount</th>
                                    <th colSpan="2">CGST</th>
                                    <th colSpan="2">SGST</th>
                                    <th rowSpan="2">Total (₹)</th>
                                </tr>
                                <tr>
                                    <th>Rate %</th>
                                    <th>Amount</th>
                                    <th>Rate %</th>
                                    <th>Amount</th>
                                </tr>
                            </>
                            ) 
                            :
                            (
                            <>
                                <tr>
                                    <th>Sl. No.</th>
                                    <th>SAC Code</th>
                                    <th>Particulars</th>
                                    <th>Details</th>
                                    <th>Amount</th>
                                    <th>GST</th>
                                    <th>GST Amount</th>
                                    <th>Total (₹)</th>
                                </tr>
                            </>
                            ) 
                        }
                    </thead>
                    <tbody>
                        {data.map((item, idx) => (invoice.stateId == 16 ?
                            (
                            <tr  key={idx}>
                                    <td >{idx + 1}</td>
                                    <td >{item.code}</td>
                                    <td className="t-a-l">{item.itemName}</td>
                                    <td >{item.details}</td>                                                                                
                                    <td >{toIndianCurrency(item.rate)}</td>
                                    <td >{item?.gst / 2}%</td>
                                    <td >{toIndianCurrency(item.gstAmount / 2)}</td>
                                    <td >{item?.gst / 2}%</td>
                                    <td >{toIndianCurrency(item.gstAmount / 2)}</td>
                                    <td >{toIndianCurrency(item.total)}</td>
                                </tr>
                            ):(
                            <tr  key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>{item.code}</td>
                                    <td className="t-a-l">{item.itemName}</td>    
                                    <td>{item.details}</td>                                                                                                                    
                                    <td >{toIndianCurrency(item.rate)}</td>
                                    <td >{item.gst}%</td>
                                    <td >{toIndianCurrency(item.gstAmount)}</td>
                                    <td >{toIndianCurrency(item.total)}</td>
                                </tr>
                            )
                            ))
                        }
                        {invoice.stateId == 16 ?
                        (                                            
                        <tr>
                            <td colspan="4">SUB TOTAL</td>
                            <td >{toIndianCurrency(totalRate)}</td>
                            <td></td>
                            <td>{toIndianCurrency(tax / 2)}</td>
                            <td></td> 
                            <td>{toIndianCurrency(tax / 2)}</td>
                            <td>{toIndianCurrency(totalAmount)}</td>
                        </tr>
                        ):
                        (
                        <tr>
                            <td colspan="4">SUB TOTAL</td>
                            <td>{toIndianCurrency(totalRate)}</td>
                            <td></td>
                            <td>{toIndianCurrency(tax)}</td>
                            <td>{toIndianCurrency(totalAmount)}</td>
                        </tr>
                        )                                        
                        }
                    </tbody>
                </table>

                <div className="customer-container">
                    <div className="invoice-summary">
                        <p><strong>Non Taxable Amount: Rs.:</strong> {toIndianCurrency(Math.round(nonTaxableAmount))}</p>
                        <p><strong>In Words: </strong>{RsPaise(Math.round(totalAmount).toFixed(2))} Only.</p>
                    </div>
                    <div className="invoice-summary">
                        {
                            invoice.stateId == 16 ?
							(
                                <>
                                    <p><strong>CGST @ 9%: </strong>{toIndianCurrency(items.filter((a) => a.gst == 18).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)}</p>
                                    <p><strong>SGST @ 9%: </strong>{toIndianCurrency(items.filter((a) => a.gst == 18).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)}</p>
                                    <p><strong>CGST @ 2.5%: </strong>{items.filter(a=>a?.gst==5).length == 0 ? '' : toIndianCurrency(items.filter((a) => a.gst == 5).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)}</p>
                                    <p><strong>SGST @ 2.5%: </strong>{items.filter(a=>a?.gst==5).length == 0 ? '' : toIndianCurrency(items.filter((a) => a.gst == 5).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)}</p>
                                </>                                
                            ) 
                            :
                            (
                                <>
                                <p><strong>GST @ 18%: {toIndianCurrency(items.filter((a) => a.gst == 18).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0))}</strong></p>
                                <p><strong>GST @ 5%: {toIndianCurrency(items.filter((a) => a.gst == 5).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0))}</strong></p>
                            </>  
                            )
                        }
                        <p><strong>Total Invoice Amount: </strong>{toIndianCurrency(Math.round(totalAmount))}</p>
                        <p><strong>Advance: </strong>{toIndianCurrency(parseFloat(invoice.advanceDeposite))}</p>
                        <p><strong>Net Amount: </strong>{netAmount == ' ₹NaN' ? 0 : toIndianCurrency(Math.round(netAmount))}</p>
                    </div>
                </div>
       
                <div className="new-invoice-header"></div>
                <div className="invoice-conditions">
                    <ul>
                        <li>Subject to Bangalore Jurisdiction.</li>
                        <li>Overdue intrest will be charged @25% p.a after 30 days.</li>
                        <li>Subject to your paying short collection of custom duty under section 28(i) of the CA 1962.</li>
                        <li>Cheque / DD should be made out of M/s TGL EXPRESS INDIA PRIVATE LIMITED.</li>
                    </ul>
                </div>
                <div className="bank-details">
                    <p><strong>Bank Name:</strong> State Bank of India</p>
                    <p><strong>Account Number:</strong> 43240724485</p>
                    <p><strong>IFCS Code:</strong> SBIN0017736</p>
                    <p><strong>PAN:</strong> AAJCT3309H</p>
                </div>
                <div className="bank-details">
                    <strong>This is computer generated invoice, seal and sign not required.</strong>
                </div>

            </div>
            <div className="footer">
                    <button onClick={() => {
                        setOpenModal(false);
                    }}>
                        Cancel
                    </button>
                    {GetButtonType()}
                </div>
        </div>
    </div>
    );
}

export default InvoicePreview;
