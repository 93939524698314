import React, {useState, useContext, useEffect} from "react";
import '../../../styles/taxInvoice.css';
import Logo from '../../../images/logo.png'
import { Context } from "../../../context/MenuContext";

const PaymentInvoice =()=>{
    const [showModal , setShowModal] =  useState(false);
    const [, setSelectedMenu, , setSelectedSubMenu] = useContext(Context);

    useEffect(()=>{
      setSelectedMenu('sales');
      setSelectedSubMenu('paymentInvoice');
    },[])
    
    return (
        <>
        <button onClick={()=>setShowModal(true)}>
            Open Modal
        </button>
        { showModal ?
        <div className="modalBackground" id='DivIdToPrint'>
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <button onClick={() => {
                        setShowModal(false);
                    }}>
                        X
                    </button>
                </div>
                <div className="invoice-box">
                    <form>
        <div class="container">
   <div class="col-md-12">
      <div class="invoice">
         {/* begin invoice-company */}
         <div>
               TAX INVOICE
            </div>
            <hr class="hr opacity-100" />
         <div class="row">
            <div id="brandingInvoice" class="col-sm-5">
               <img src="/static/media/logo.5a36f8dad321a4a571f2.png" alt="Company Logo" class="logo" />
            </div>
            <div id="brandingInvoice" class="col-sm">
               <h5 className="">TGL EXPRESS INDIA PRIVATE LIMITED</h5>
               <div className="fs-12 invoice-subheading">
                  <span className="">Royale Township No.06, Makali New APMC  Huskur Main Road</span><br />
                  <span className="m-4">Nagaruru (V) Dasanapura (H), BENGALURU - 562123</span><br />
                  <span className="m-4">GST No. 29AAJCT3309H1Z6, info@tglexpressindia.com</span>
               </div>
            </div>
         </div>
         <div className="fs-12 row">
            <div className="col-sm">CIN: U63040MH1993PTC070564</div>
            <div className="col-sm">PAN: AAACP4657M </div>
            <div className="col-sm">LUT: AD290323033033K</div>
            <div className="col-sm">GSTIN: 29AAACP4657M1ZV</div>
         </div>
         <hr class="hr mt-minus20 opacity-100" />
         {/* end invoice-company */}
         {/* begin invoice-header */}
         <div class="invoice-header row">
            <div class="invoice-from col-sm">
               <small>Recipient:</small>
               <div class="lh-25">
                  <strong class="text-inverse">TGL EXPRESS INDIA PRIVATE LIMITED</strong><br />
                  GROUND FLOOR, NO 79, M.S RAMAIAH ENCLAVE,<br />
                  NAGASANDRA POST, 2ND MAIN 8TH MILE,<br />
                  Bengaluru, Bengaluru Urban, Karnataka, 560073 <br />
                  Bangalore - 560073, Karnataka, India <br />
                  GSTIN: 29AAJCT3309H1Z6<br />
                  PAN: AAJCT3309H<br />
                  Place of Supply: 29-Karnataka<br />
                  IRN : 489655f5d8e44ac41193541aa99fcec265b9a779f7be790c6398534f659d8ca9
               </div>
            </div>
            <div className="col-sm m-65">
               <div class="invoice-to">
                  <div className="m-t-5 m-b-5">
                     <strong>Invoice No</strong><br />
                     <span>Invoice Date</span><br />
                     <span>Due Date</span><br />
                     <span>Invoice Currency</span><br />
                     <span>Shipment Type</span><br />
                     <span>Job Number</span><br />
                     <span>Job Number</span><br />
                     <span>Ack No</span><br />
                     <span>Ack Date</span><br />
                  </div>
               </div>
               <div class="invoice-date">
                  <div className="m-t-5 m-b-5">
                     <strong>: PFBGL23060006</strong><br />
                     <span>: 06-Jun-2023</span><br />
                     <span>: 06-Jun-2023</span><br />
                     <span>: INR</span><br />
                     <span>: Air Import</span><br />
                     <span>: PFBGL/AI/2324/00039</span><br />
                     <span>: </span><br />
                     <span>: 112316464954347</span><br />
                     <span>: 08-Jun-2023 12:53:00</span><br />
                  </div>
               </div>
            </div>            
         </div>
         <div class="row fs-12">
            <hr class="hr opacity-100" />
            <div className="col-sm">
               <div class="invoice-to">
                  <div className="m-t-5 m-b-5">
                     <span>Incoterms</span><br />
                     <span>Shipper</span><br />
                     <span>Consignee</span><br />
                     <span>Loading Port</span><br />
                     <span>Destination Port</span><br />
                     <span>FPOD</span><br />
                     <span>Flight No & Date</span><br />
                  </div>
               </div>
               <div class="invoice-date">
                  <div className="m-t-5 m-b-5">
                     <strong>: test data</strong><br />
                     <span>: GEOSOLUTION GOTEBORG AB</span><br />
                     <span>: XTRAGEN TECHNOLOGIES PRIVATE LIMITED</span><br />
                     <span>: Hong Kong, HKG</span><br />
                     <span>: Bangalore, BLR</span><br />
                     <span>: test data</span><br />
                     <span>: test data</span><br />
                  </div>
               </div>
            </div> 
            <div className="col-sm m-65">
               <div class="invoice-to">
                  <div className="m-t-5 m-b-5">
                     <span>Airline</span><br />
                     <span>MAWB No & Date</span><br />
                     <span>HAWB No & Date</span><br />
                     <span>Cargo type</span><br />
                     <span>Gross Wt.</span><br />
                     <span>Chargeable Wt.</span><br />
                     <span>IGM No.& Date</span><br />
                     <span>BE No. & Date</span><br />
                  </div>
               </div>
               <div class="invoice-date">
                  <div className="m-t-5 m-b-5">
                     <span>: European Air Transport</span><br />
                     <span>: 61536945624, 04-Jun-2023</span><br />
                     <span>: SL23060027, 04‑Jun‑2023</span><br />
                     <span>: LOOSE </span> <span>Packages : 145</span><br />
                     <span>: 994.0 kg</span><br />
                     <span>: 1153.5 kg</span><br />
                     <span>: 2274500, 06-Jun-2023</span><br />
                     <span>: test data</span><br />
                  </div>
               </div>
            </div>            
         </div>
         <hr class="hr opacity-100" />
         {/* end invoice-header */}
         {/* begin invoice-content */}
         <div class="invoice-content">
            {/* begin table-responsive */}
            <div class="table-responsive">
               <table class="table table-invoice">
                  <thead>
                     <tr>
                        <th>TASK DESCRIPTION</th>
                        <th class="text-center" width="10%">RATE</th>
                        <th class="text-center" width="10%">HOURS</th>
                        <th class="text-right" width="20%">LINE TOTAL</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>
                           <span class="text-inverse">Website design &amp; development</span><br />
                           <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id sagittis arcu.</small>
                        </td>
                        <td class="text-center">$50.00</td>
                        <td class="text-center">50</td>
                        <td class="text-right">$2,500.00</td>
                     </tr>
                     <tr>
                        <td>
                           <span class="text-inverse">Branding</span><br />
                           <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id sagittis arcu.</small>
                        </td>
                        <td class="text-center">$50.00</td>
                        <td class="text-center">40</td>
                        <td class="text-right">$2,000.00</td>
                     </tr>
                     <tr>
                        <td>
                           <span class="text-inverse">Redesign Service</span><br />
                           <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id sagittis arcu.</small>
                        </td>
                        <td class="text-center">$50.00</td>
                        <td class="text-center">50</td>
                        <td class="text-right">$2,500.00</td>
                     </tr>
                  </tbody>
               </table>
            </div>
            {/* end table-responsive */}
            {/* begin invoice-price */}
            <div class="invoice-price">
               <div class="invoice-price-left">
                  <div class="invoice-price-row">
                     <div class="sub-price">
                        <small>SUBTOTAL</small>
                        <span class="text-inverse">$4,500.00</span>
                     </div>
                     <div class="sub-price">
                        <i class="fa fa-plus text-muted"></i>
                     </div>
                     <div class="sub-price">
                        <small>PAYPAL FEE (5.4%)</small>
                        <span class="text-inverse">$108.00</span>
                     </div>
                  </div>
               </div>
               <div class="invoice-price-right">
                  <small>TOTAL</small> <span class="f-w-600">$4508.00</span>
               </div>
            </div>
            {/* end invoice-price */}
         </div>
         {/* end invoice-content */}
         {/* begin invoice-note */}
         <div class="invoice-note">
            * Make all cheques payable to [Your Company Name]<br />
            * Payment is due within 30 days<br />
            * If you have any questions concerning this invoice, contact  [Name, Phone Number, Email]
         </div>
         {/* end invoice-note */}
         {/* begin invoice-footer */}
         <div class="invoice-footer">
            <p class="text-center m-b-5 f-w-600">
               THANK YOU FOR YOUR BUSINESS
            </p>
            <p class="text-center">
               <span class="m-r-10"><i class="fa fa-fw fa-lg fa-globe"></i> matiasgallipoli.com</span>
               <span class="m-r-10"><i class="fa fa-fw fa-lg fa-phone-volume"></i> T:016-18192302</span>
               <span class="m-r-10"><i class="fa fa-fw fa-lg fa-envelope"></i> rtiemps@gmail.com</span>
            </p>
         </div>
         {/* end invoice-footer */}
      </div>
   </div>
</div>
</form>
</div>
</div>
</div> : ''}
</>
    )
}

export default PaymentInvoice;



{/* <div className='custom-container'>
            <div class="card-custom card-custom-bgi mt-5">
                <div class="card-header">
                    <span>Payment Invoice Header Part</span>
                </div>
                <div class="card-body">
                    <span>Payment Invoice Address Part</span>
                </div>
                <div class="card-body">
                    <span>Payment Invoice Shpping dettails part</span>
                </div>
                <div class="card-custom-body">
                    <span>Payment Invoice items parts</span>
                </div>
                <div class="card-custom-body">
                    <span>Payment Invoice bank and other tax cal part</span>
                </div>
                <div class="card-custom-body">
                    <span>Payment Invoice footer part</span>
                </div>
            </div>
        </div> */}