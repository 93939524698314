import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import Logo from '../../../images/logo.png'
import {RsPaise, dateFormatter} from '../../CommonJsFunc/CommonJsFunc';

const PreviewModal = (props) =>{
    const {invoiceDetails, setInvoiceDetails, setPreviewModal, action, handleSubmit } = props;

    const data = invoiceDetails?.debitNoteItems?.filter((a) => a.itemDetail !== '')
    const totalAmount = invoiceDetails?.debitNoteItems?.map((a) => parseFloat(a.totalAmount)).reduce((prev, curr) => prev + curr, 0);
    const componentRef = useRef();

    const generateInvoiceNumber = () => {
        axios
        .get('/api/Lookup/GenerateDNNumber')
        .then((response) => {
            setInvoiceDetails({
              ...invoiceDetails,
              dnNumber: response.data,
              issueDate: new Date()
            })
        })
        .catch((e) => {
        if (e.name !== 'AbortError') {
            console.log('fail:', e.message)
        }
        });
      }; 

    useEffect(() => {
        if(action === 'Save'){
           generateInvoiceNumber();
        }
     }, []);

    const GetButtonType = () => {
        switch (action) {
            case 'Save':
            case 'Update':
                return (<button type="submit" onClick={handleSubmit}>{action}</button>);
            case 'Print':
                return <button type="submit" onClick={handlePrint}>{action}</button>;
            default:
            // code block
        }
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return  (
        <div className="modalBackground" id='DivIdToPrint'>
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <button onClick={() => {
                        setPreviewModal(false);
                    }}>
                        X
                    </button>
                </div>
                <div className="invoice-box" ref={componentRef}>
                    <form>
                        <table className='bg-aliceblue'>
                            <tr className="top border-2">
                                <td colSpan="2" className='border-2'>
                                    <table>
                                        <tr>
                                            <td className=''>
                                                <div id="brandingInvoice" className=''>
                                                    <img src={Logo} alt="Company Logo" className="logo" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='m-l-15'>
                                                    <div id="brandingInvoice">
                                                        <h5 className="c-head">TGL EXPRESS INDIA PRIVATE LIMITED</h5>
                                                    </div>
                                                    <div className="invoice-subheading c-subhead">
                                                        <span className="">Royale Township No.06, Makali New APMC  Huskur Main Road</span><br />
                                                        <span className="m-4">Nagaruru (V) Dasanapura (H), BENGALURU - 562123</span><br />
                                                        <span className="m-4">GST No. 29AAJCT3309H1Z6, info@tglexpressindia.com</span>
                                                    </div>
                                                </div>    
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="t-a-c border-2">
                                    <strong>Debit Note</strong>                                    
                                </td>
                            </tr>
                            <tr>
                                <td className="border-2">
                                    <strong>Date Of Issue: </strong>{dateFormatter(invoiceDetails.issueDate)}                                    
                                </td>
                                <td className="border-2">
                                    <strong>{invoiceDetails.dnNumber} </strong>                                    
                                </td>
                            </tr>
                            <tr>
                                <td className='border-2'>
                                    <div><strong className='text-decoration-underline'>Billing Address,</strong></div>
                                    <div className='m-4'>
                                        <div className='fs-5 p-10px'>
                                            <strong>M/s. {invoiceDetails.clientName}</strong><br />
                                        </div>
                                        <div className='fs-6 m-auto p-10px m-l-45'>
                                            {invoiceDetails.billingAddress}<br />
                                            {invoiceDetails.billingAddressLine}<br />
                                            {invoiceDetails.billingCity}<br />
                                            {invoiceDetails.billingStateName}<br />
                                        </div>
                                    </div>        
                                </td>
                                <td className='border-2'>
                                    <div><strong className='text-decoration-underline'>Shipping Address,</strong></div>
                                    <div className='m-4'>
                                        <div className='fs-5 p-10px'>
                                            <strong>M/s. {invoiceDetails.consignee}</strong><br />
                                        </div>
                                        <div className='fs-6 m-auto p-10px m-l-45'>
                                            {invoiceDetails.shippingAddress}<br />
                                            {invoiceDetails.shippingAddressLine}<br />
                                            {invoiceDetails.shippingCity}<br />
                                            {invoiceDetails.shippingCountryName}<br />
                                        </div>
                                    </div>        
                                </td>
                            </tr>
                            <tr>
                                <td className="border-2">
                                    <strong>Country of Origin: </strong>{invoiceDetails.countryOfOrigin}                                    
                                </td>
                                <td className="border-2">
                                    <strong>Port of Discharge: </strong>{invoiceDetails.portOfDischarge}                                     
                                </td>
                            </tr>
                            <tr>
                                <td className="border-2">
                                    <strong>Airway Bill / BL No.: </strong>{invoiceDetails.airwayBillNo}                                    
                                </td>
                                <td className="border-2">
                                    <strong>BOE NO & DT: </strong>{invoiceDetails.boeNo}&nbsp; &nbsp; &nbsp;{dateFormatter(invoiceDetails.boeDate)}                                     
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className='border-2 '>
                                    <strong>Description of goods: </strong>{invoiceDetails.subject}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className='border-2'>
                                    <table className=' t-a-c'>
                                            <thead>
                                                <tr className="bg-c-head">
                                                    <th className='border-2 w-5'>S.No.</th>
                                                    <th className='border-2 w-45'>Particulers</th>
                                                    <th className='border-2 w-10'>Quantity</th>
                                                    <th className='border-2 w-10'>Rate</th>
                                                    <th className='border-2 w-10'>Total (₹)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {data?.map((item, idx) => 
                                                (<tr className="border-2" key={idx}>
                                                        <td className="border-2">{idx + 1}</td>
                                                        <td className="border-2 t-a-l">{item.itemDetail}</td>                                        
                                                        <td className="border-2">{item.quantity}</td>
                                                        <td className="border-2">{item.rate.toFixed(2)}</td>
                                                        <td className="border-2">{item.totalAmount.toFixed(2)}</td>
                                                    </tr>))
                                            }
                                            </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr className="border-2 fw-bold text-lg-end">
                                <td colSpan="2">Total Amount: {Math.round(totalAmount).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="currancyText border-2">
                                    <div>
                                        <strong>In Words: </strong>{RsPaise(Math.round(totalAmount).toFixed(2))} Only.
                                    </div>                                    
                                </td>
                            </tr>
                            <tr className="border-2">
                                <td colSpan="2" className='border-2'>
                                    <table>
                                        <tr>
                                            <td>
                                                <ul>
                                                    <li>Subject to Bangalore Jurisdiction.</li>
                                                    <li>Overdue intrest will be charged @25% p.a after 30 days.</li>
                                                    <li>Subject to your paying short collection of custom duty under section 28(i) of the CA 1962.</li>
                                                    <li>Cheque / DD should be made out of M/s TGL EXPRESS INDIA PRIVATE LIMITED.</li>
                                                </ul>
                                            </td>
                                           
                                        </tr>
                                    </table>
                                </td>
                            </tr>							
                            <tr className="border-2">
                                <td colSpan="2" className="border-2">
                                    <div className='row'>
                                        <div className="col-md-4">OUR BANK: State Bank of India</div>
                                        <div className="col-md-4">A/C : 43240724485</div>
                                        <div className="col-md-4">IFSC : SBIN0017736</div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="border-2">
                                <td colSpan="" className="border-2">
                                    PAN: AAJCT3309H
                                </td>
                                <td colSpan="" className="border-2 text-lg-center">
                                    <div>
                                        <strong>This is computer generated invoice, seal and sign not required.</strong>
                                    </div>
                                    
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div className="footer">
                    <button onClick={() => {
                        setPreviewModal(false);
                    }}>
                        Cancel
                    </button>
                    {GetButtonType()}
                </div>
            </div>
        </div>
    )
}

export default PreviewModal;