import React, { useState, useContext } from 'react';
import { FaMinusCircle } from 'react-icons/fa';
import axios from 'axios';
import { Context } from '../../../context/InvoiceContext';

const InvoiceItem = (props) => {
    const item = props.item;
    const idx = props.idx;
    const notify = props.notify;
    const particulers = props.particulers;
    let itemCode = '';
    const defaultItems = () => {
        return {
            itemId: 0,
            itemName: '',
            details: '',
            code: '',
            rate: 0,
            gst: 0,
            gstAmount: 0,
            total: 0,
            invoiceNumber: ''
        }
    }
    const getTaxRates =()=>{
        const taxRates = [{label:'0%', value: 0},{label:'5%', value: 5},{label:'18%', value: 18}];
        return taxRates;
    }
    const [items, setItems] = useContext(Context);
    const calculateAmount = (e, idx) => {
        if(item.itemName !== '' || ( item.rate !== '' && item.gst >= 0)) {
           
            const taxAmount = ((parseFloat(item.rate)/100) * parseInt(item.gst)).toFixed(2);
            const old = items[idx];
            const updated = { 
                ...old, 
                rate: parseFloat(item.rate),
                total: parseFloat((parseFloat(item.rate) + parseFloat(taxAmount)).toFixed(2)), 
                gstAmount: parseFloat(taxAmount)
            }
            const clone = [...items];
            clone[idx] = updated;
        }
    }
    const onTaxChange = (e, idx) => {
        const taxAmount = ((parseFloat(item.rate)/100) * parseInt(e.target.value)).toFixed(2);
        const old = items[idx];
        const updated = { 
            ...old, 
            gst: parseInt(e.target.value), 
            gstAmount: parseFloat(taxAmount),
            total: parseFloat((parseFloat(item.rate) + parseFloat(taxAmount)).toFixed(2))  
        }
        const clone = [...items];
        clone[idx] = updated;
        setItems(clone);
    }
  
    const handleChange = (e, idx) => {
        const { target } = e;
        const {
            name, type, value, checked,
        } = target; 
        const old = items[idx];
        const updated = { ...old, [name]: value }
        const clone = [...items];
        clone[idx] = updated;
        const emptyItem = clone.filter((e)=>e.itemName === '').length;
        if(name === 'itemName'){
            clone[idx]['code'] = particulers.filter((i) => i.name === value)[0].sacCode
            clone[idx]['itemName'] = value
        }
        if(emptyItem === 0){
            setItems(clone.concat(defaultItems()));
        }
        else{
            setItems(clone);
        }
    }
    const handleValidation = (e) => {
        const { target } = e;
        const {
            name, type, value, checked,
        } = target; 
        const old = items[idx];
        const updated = { ...old, [name]: value }
        const clone = [...items];
        clone[idx] = updated;
        const emptyItem = clone.filter((e)=>e.itemName === '').length;
        if(name === 'itemName' && emptyItem === 0){    
            setItems(clone.concat(defaultItems()));
        }
        else{
            setItems(clone);
        }
      };
    const toIndianCurrency = (num) => {
        const curr = num?.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR'
        });
        return curr;
    };
    const removeItem = (id) => {
        
        axios
            .get(`/api/Lookup/DeleteItem/${id}/InvoiceItem`)
            .then((response) => {
                if(response.data === "success"){
                    notify('success', 'Successfully Removed');
                }
                else{
                    notify('error', "Something Went Wrong");
                }
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                    notify('error', e.message);
                }
            });
    }
    const handleRemove = (itemId, idx) => {
        const data = items.filter((a)=> a.itemId !== itemId );
        setItems(data)
        if(itemId !== 0){
            removeItem(itemId);
        }
      };
    return (
        <>
        <tr className="p-10px item" key={idx}>
            <td className='vertical-align'><span>{idx+1}</span></td>
            <td>
                <select name="itemName" value={item.itemName} onChange={(e) => handleChange(e, idx)} className="form-control" >
                    <option value="0">Select Particuler</option>
                    {particulers.map((option) => (
                        <option value={option.name}>{option.name}</option>
                    ))}
                </select>
            </td>
            <td className='vertical-align'>
                <input
                    maxLength={255}
                    name="details"
                    className="form-control" 
                    value={item.details}
                    placeholder="Details"
                    onChange={(e) => handleChange(e, idx)}
                />
            </td>
            <td className='vertical-align'>
                <input
                    maxLength={255}
                    name="code"
                    className="form-control" 
                    value={item.code}
                    placeholder="SAC Code"
                    onChange={(e) => handleChange(e, idx)}
                />
            </td>
            <td className='vertical-align'>
                <input 
                    type='number' 
                    name="rate" 
                    className="form-control" 
                    onBlur={(e) => calculateAmount(e, idx)} 
                    value={item.rate} onChange={(e) => handleChange(e, idx)}
                />
            </td>
            <td className='vertical-align'>
                <select value={item.gst} onChange={(e) => onTaxChange(e, idx)} className="form-control" >
                    {getTaxRates().map((option) => (
                    <option value={option.value}>{option.label}</option>
                    ))}
                </select>
            </td>
            <td className='vertical-align'><span>{item.gstAmount}</span></td>
            <td className='vertical-align'><span>{toIndianCurrency(item.total)}</span></td>
            <td className='vertical-align'>
                <button
                  type="button"
                  className="btn btn-link m-0 p-0 color-r"
                  title=""
                  onClick={(e) => handleRemove(item.itemId, idx)}
                >
                  <FaMinusCircle />
                </button>
            </td>
        </tr>
    </>
    );
}

export default InvoiceItem;
