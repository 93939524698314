import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Input from '../../Common/Input';
import Dropdown from '../../Common/Dropdown';
import RadioButtons from '../../Common/RadioButtons';
import InvoiceItem from './InvoiceItem';
import InvoicePopUp from './InvoicePopUp'
import Loader from '../../Common/Loader';
import { Context } from '../../../context/InvoiceContext';
import { Context as MenuContext } from '../../../context/MenuContext';
import ApiTypeAhead from '../../Common/ApiTypeAhead';

function NewInvoice(props) {
    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
    const currencyType = [
        {label:'RUPEES', value: 'Rs'},
        {label:'DOLLOR', value: 'USD'},
        {label:'EURO', value: 'EUR'},
        {label:'SGD', value: 'SGD'},
        {label:'HKD', value: 'HKD'}
    ];
    let { id } = useParams();
    const defaultItems = () => {
        return {
            itemId: 0,
            itemName: '',
            details: '',
            code: '',
            rate: 0,
            gst: 0,
            gstAmount: 0,
            total: 0,
            invoiceNumber: ''
        }
    }
    const defaultInvoice = () => {
        return {
            invoiceNumber: '', 
            carrier: '',
            invoiceType: '', 
            clientName: '',
            clientAddress: '',
            clientAddressLine: '',
            clientGSTNo: '',
            sB_BOENo: 0,
            sB_BOENoDate: new Date(),
            mbL_MAWBNo: 0,
            mbL_MAWBNoDate: new Date(),
            bhL_HAWBNo: 0,
            bhL_HAWBNoDate: new Date(),
            package: '',
            weight: '',
            exchangeRate: 0,
            exchangeCurr: 'Rs',
            chargeableWt: '',
            containerNo: '',
            advanceDeposite: 0,
            shipper: '',
            shipperCountry: '',
            shipperPort: '',
            shippedInvoiceNo: '',
            shippedInvoiceDate: new Date(),
            invoiceDescription: '',
            invoiceDate: '',
            stateId: 0,
            stateName: '',
            items: items,
          };
      };

    const [items, setItems, buttonType , setButtonType] = useContext(Context);
    const [modalOpen, setModalOpen] = useState(false);
    const [invoice, setInvoice] = useState(defaultInvoice());
    const [validation, setValidation] = useState({});
    const [clientName, setClientName] = useState('');
    const [loader, setLoader] = useState(false);
    const [particulers, setParticulers] = useState([]);

    const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(MenuContext);

    const parseValue = (name, value) => {
        switch (name) {
            case 'stateId':
                return parseInt(value);
            case 'advanceDeposite':
            case 'exchangeRate':
                return parseFloat(value === '' ? 0 : value);
            default:
                return value;
        }

    }
    const getParticulers = () =>{
        axios
            .get('/api/Lookup/GetParticulers')
            .then((response) => {
                setParticulers(response.data);
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
            });
    }
    const handleChange = (e) => {
        const { target } = e;
        const {
            name, value, type
        } = target; 
        if(type === 'radio'){
            setValidation((v) => {
                return {
                    ...v,
                    [name]: ''
                  };
            });
        }
        setInvoice((ps) => {
            return {
                ...ps,
                [name]: parseValue(name, value),
            };
        })
        setValidation((v) => {
            return {
                ...v,
              };
        });

        if(name === "clientName"  && isNaN(value)){
            setClientName(value);
        }        
    }

    const reload = () => {
        if (id) {
            setLoader(true);
            setButtonType('Update');
            setItems([])
          axios
            .get(`/api/Lookup/GetInvoice/${id}`)
            .then((response) => {
                setInvoice(response.data);
                setItems(response.data.items)
                setLoader(false);
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                    notify('error', e.message);
                }
                setLoader(false);
            });
        } else {
            setInvoice(defaultInvoice());
            setItems([defaultItems()]);
            setLoader(false);
        }
      };
    
      useEffect(() => {
        setSelectedMenu('invoice');
		setSelectedSubMenu('');
        reload();
        setButtonType('');
        getParticulers();
      }, []);

    const handleValidation = (e) => {
        const { target } = e;
        const {
          name, required, value,
        } = target;
        if (required === true) {
            setValidation({
                ...validation,
                [name]: value ? '' : 'Required *',
              });
        }

        if (name === "clientName" || name === 'shipper') {
            setInvoice({
                ...invoice,
                [name]: value,
              });
        }
      };
    
      const handleSubmit = () => {
        const validation = {
            invoiceType: invoice.invoiceType ? '' : "Please select invoice type",
            carrier: invoice.carrier ? '' : "Please select carrier type",
            clientName: invoice.clientName ? '' : "Please enter client name.",
            clientAddress: invoice.clientAddress ? '' : "Please enter client address.",
            clientGSTNo: invoice.clientGSTNo ? '' : "Please enter client GST No.",
            sB_BOENo: invoice.sB_BOENo ? '' : "Please enter SB/BOENO",
            sB_BOENoDate: invoice.sB_BOENoDate ? '' : "Please select SB/BOENO Date",
            mbL_MAWBNo: invoice.mbL_MAWBNo ? '' : "Please enter BL/MAWBNO",
            mbL_MAWBNoDate: invoice.mbL_MAWBNoDate ? '' : "Please enter BL/MAWBNO Date",
            bhL_HAWBNo: invoice.bhL_HAWBNo ? '' : "Please enter BHL/HAWBNO",
            bhL_HAWBNoDate: invoice.bhL_HAWBNoDate ? '' : "Please enter BHL/HAWBNO Date",
            package: invoice.package ? '' : "Please enter Package",
            containerNo: invoice.containerNo ? '' : "Please enter container No",
            shipper: invoice.shipper ? '' : "Please enter shipper",
            shippedInvoiceNo: invoice.shippedInvoiceNo ? '' : "Please enter shipping Invoice No",
            shippedInvoiceDate: invoice.shippedInvoiceDate ? '' : "Please enter shipping Invoice Date",
            invoiceDescription: invoice.invoiceDescription ? '' : "Please enter invoice description",
            stateId: invoice.stateId ? '' : "Please select stateId",
            item: items[0].partName !== '' ? '' : 'Please add Items',
        };
        let validErrorExist = false;
    
        Object.keys(validation).forEach((o) => {
          if (validation[o]) {
            validErrorExist = true;
          }
        });
    
        setValidation(validation);
        if (!validErrorExist && buttonType !== 'Print') {
            setModalOpen(true); 
            setInvoice({...invoice, items: items.filter((e) => e.itemName !== '' && e.rate !== '')});
            if (id) 
                setButtonType('Update');
            else    
               setButtonType('Save');
        }
        if (buttonType === 'Print'){
            setInvoice(defaultInvoice());
            setItems([defaultItems()]);
            setClientName('');
            setButtonType('');
        }
      };
    useEffect(() => {
        if(invoice.clientName !== '' && id === undefined){
            axios
            .get(`api/Lookup/GetClientAddress?clientName=${invoice.clientName}`)
            .then((response) => {
                var result = response.data;
                setInvoice({
                    ...invoice,
                    clientAddress: result.clientAddress,
                    clientAddressLine: result.clientAddressLine,
                    clientGSTNo: result.clientGSTNo
                });
                setValidation({
                    ...validation,
                    clientAddress: result.clientAddress ? '' : 'Required *',
                    clientAddressLine: result.clientAddressLine ? '' : 'Required *',
                    clientGSTNo: result.clientGSTNo ? '' : 'Required *'
                  });
                setLoader(false);
            })
            .catch((err) => {
                if (err.name !== 'AbortError') {
                    console.error(err);
                    notify('error', err.message);
                }
            });
            }
    }, [clientName]);
    return (
        <>
         {modalOpen && <InvoicePopUp setOpenModal={setModalOpen} invoice={invoice} />}
         {loader ? <Loader /> : ''}
            <>
            <div className='custom-container'>
			<div className='card-custom card-custom-bgi mt-5'>
            <h2 className="section-title">Generate Invoice</h2>
            <div className='card-custom-body'>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Invoice Type</div>
                <div className="col-md-3">
                    <RadioButtons
                        name="invoiceType"
                        value={invoice.invoiceType}
                        inline
                        options={{ IMP: 'IMP', EXP: 'EXP' }}
                        onChange={handleChange}
                        required
                        errorText={validation.invoiceType}
                    />
                </div>
                <div className="col-md-2 mt-4">Carrier Type</div>
                <div className="col-md-3">
                    <RadioButtons
                        name="carrier"
                        value={invoice.carrier}
                        inline
                        options={{ AIR: 'AIR', SEA: 'SEA' }}
                        onChange={handleChange}
                        required
                        errorText={validation.carrier}
                    />
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Client Name</div>
                <div className="col-md-8">
                    {
                        invoice.clientName !== '' || id === undefined ? 
                        <ApiTypeAhead
                            name="clientName"
                            defaultId={invoice.clientName}
                            displayKey="clientName"
                            query={invoice.clientName}
                            placeholder="Start typing to search"
                            url="/api/Lookup/GetClientName"
                            onChange={handleChange}
                            required
                            errorText={validation.clientName}
                            onBlur={handleValidation}
                        />
                      : <div>Loading....</div>
                    }
                    </div>
            </div>
            <div className="row p-10px">
                <div className="col-md-4 mt-4">Client Address</div>
                <div className="col-md-8">
                    <Input 
                        name="clientAddress"
                        required
                        type="text" 
                        placeholder="Enter Client Address"
                        value={invoice.clientAddress}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.clientAddress}
                    />
                    <br/>
                </div>
            </div>
            <div className="row p-10px">
                <div className="col-md-4 mt-4">Client Address Line</div>
                <div className="col-md-8">
                    <Input 
                        name="clientAddressLine"
                        required
                        type="text" 
                        placeholder="Enter Client Address Line"
                        value={invoice.clientAddressLine}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.clientAddressLine}
                    />
                    <br/>
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Client Address State</div>
                <div className="col-md-8">
                    <Dropdown
                          name="stateId"
                          value={invoice.stateId}
                          placeholder="Select State"
                          required
                          url="/api/Lookup/GetStates"
                          onChange={handleChange}
                          errorText={validation.stateId}
                          onBlur={handleValidation}
                        />
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Client GST NO</div>
                <div className="col-md-8">
                    <Input 
                        name="clientGSTNo"
                        required
                        type="text" 
                        placeholder="Enter Client GST No"
                        value={invoice.clientGSTNo}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.clientGSTNo}
                    />
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">SB/BOE NO</div>
                <div className="col-md-4">
                    <Input 
                        name="sB_BOENo"
                        required
                        type="text"
                        placeholder="Enter SB/BEO NO" 
                        value={invoice.sB_BOENo}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.sB_BOENo}
                    />
                </div>
                <div className="col-md-4 mt-4">
                    <DatePicker 
                        name="sB_BOENoDate" 
                        selected={new Date(invoice.sB_BOENoDate)} 
                        onChange={(d) => 
                            setInvoice({
                                ...invoice, 
                                sB_BOENoDate : new Date(d) 
                                })
                            }
                        placeholderText='Please Select SB/BOENo Date'
                        className='form-control'
                        dateFormat="dd/MM/yyyy"     
                    />
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">MBL/MAWB NO</div>
                <div className="col-md-4">
                    <Input
                        name="mbL_MAWBNo" 
                        required
                        type="text" 
                        placeholder="Enter MBL/MAWB NO"  
                        value={invoice.mbL_MAWBNo}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.mbL_MAWBNo}
                    />
                </div>
                <div className="col-md-4 mt-4">
                    <DatePicker 
                        name="mbL_MAWBNoDate" 
                        selected={new Date(invoice.mbL_MAWBNoDate)} 
                        onChange={(d) => 
                            setInvoice({
                                ...invoice, 
                                mbL_MAWBNoDate : new Date(d) 
                                })
                            }
                        placeholderText='Please Select BL/MAWB No Date'
                        className='form-control' 
                        dateFormat="dd/MM/yyyy"       
                    />
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">HBL/HAWB NO</div>
                <div className="col-md-4">
                    <Input 
                        name="bhL_HAWBNo"
                        required
                        type="text" 
                        placeholder="Enter HBL/HAWB NO"   
                        value={invoice.bhL_HAWBNo} 
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.bhL_HAWBNo}
                    />
                </div>
                <div className="col-md-4 mt-4">
                    <DatePicker 
                        name="bhL_HAWBNoDate" 
                        selected={new Date(invoice.bhL_HAWBNoDate)} 
                        onChange={(d) => 
                            setInvoice({
                                ...invoice, 
                                bhL_HAWBNoDate : new Date(d) 
                                })
                            }
                        placeholderText='Please Select BHL/HAWBNo Date'
                        className='form-control' 
                        dateFormat="dd/MM/yyyy"       
                    />
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Shipping Invoice No & Date</div>
                <div className="col-md-4">
                    <Input 
                        name="shippedInvoiceNo"
                        required
                        type="text" 
                        placeholder="Enter Shipping Invoice No"    
                        value={invoice.shippedInvoiceNo} 
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.shippedInvoiceNo}
                    />
                </div>
                <div className="col-md-4 mt-4">
                    <DatePicker 
                        name="shippedInvoiceDate" 
                        selected={new Date(invoice.shippedInvoiceDate)} 
                        onChange={(d) => 
                            setInvoice({
                                ...invoice, 
                                shippedInvoiceDate : new Date(d) 
                                })
                            }
                        placeholderText='Please Select Shipping Date'
                        className='form-control'
                        dateFormat="dd/MM/yyyy"        
                    />
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Package</div>
                <div className="col-md-3">
                    <Input 
                        name="package"
                        required
                        type="text" 
                        placeholder="Enter Package"   
                        value={invoice.package} 
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.package}
                    />
                </div>
                <div className="col-md-2 mt-4">Weight</div>
                <div className="col-md-3">
                    <Input 
                        name="weight"
                        required
                        type="text" 
                        placeholder="Enter Weight"   
                        value={invoice.weight} 
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.weight}
                    />
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Exchange Rate</div>
                <div className="col-md-3">
                    <Input 
                        name="exchangeRate"
                        required
                        type="number" 
                        placeholder="Exchange Rate"   
                        value={invoice.exchangeRate} 
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.exchangeRate}
                    />
                </div>
                <div className="col-md-2 mt-4">Currenct Type</div>
                <div className="col-md-3 mt-4">
                    <select value={invoice.exchangeCurr} onChange={(e) => handleChange(e)} name='exchangeCurr' className="form-control" >
                        {currencyType.map((option) => (
                        <option value={option.value}>{option.label}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Advance Deposite</div>
                <div className="col-md-3">
                    <Input 
                        name="advanceDeposite"
                        type="number" 
                        placeholder="Advance Deposite (If Any)"     
                        value={invoice.advanceDeposite} 
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.advanceDeposite}
                    />
                </div>
                <div className="col-md-2 mt-4">Chargeable Weight</div>
                <div className="col-md-3">
                    <Input 
                        name="chargeableWt"
                        required
                        type="text" 
                        placeholder="Chargeable Weight"   
                        value={invoice.chargeableWt} 
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.chargeableWt}
                    />
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Container No</div>
                <div className="col-md-8">
                    <Input 
                        name="containerNo"
                        required
                        type="text" 
                        placeholder="Enter Container No"   
                        value={invoice.containerNo} 
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.containerNo}
                    />
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Shipper/Consignee</div>
                <div className="col-md-8">
                {
                        invoice.shipper !== '' || id === undefined ? 
                        <ApiTypeAhead
                            name="shipper"
                            defaultId={invoice.shipper}
                            displayKey="shipper"
                            query={invoice.shipper}
                            placeholder="Start typing to search"
                            url="/api/Lookup/GetShipperName"
                            onChange={handleChange}
                            required
                            errorText={validation.shipper}
                            onBlur={handleValidation}
                        />
                      : <div>Loading....</div>
                    }
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Shipper Country</div>
                <div className="col-md-3">
                    <Input 
                        name="shipperCountry"
                        type="text" 
                        placeholder="Shipper Country"     
                        value={invoice.shipperCountry} 
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.shipperCountry}
                    />
                </div>
                <div className="col-md-2 mt-4">Shipper Port</div>
                <div className="col-md-3">
                    <Input 
                        name="shipperPort"
                        type="text" 
                        placeholder="Shipper Port"   
                        value={invoice.shipperPort} 
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.shipperPort}
                    />
                </div>
            </div>
            <div className="row p-10px mb-3">
                <div className="col-md-4 mt-4">Invoice Description</div>
                <div className="col-md-8">
                    {/* <Input 
                        name="invoiceDescription"
                        required
                        type="text" 
                        placeholder="Enter Invoice Description"     
                        value={invoice.invoiceDescription} 
                        onChange={(e) => handleChange(e)}
                        onBlur={handleValidation}
                        errorText={validation.invoiceDescription}
                    /> */}
                    {
                        invoice.invoiceDescription !== '' || id === undefined ? 
                        <ApiTypeAhead
                            name="invoiceDescription"
                            defaultId={invoice.invoiceDescription}
                            displayKey="invoiceDescription"
                            query={invoice.invoiceDescription}
                            placeholder="Start typing to search"
                            url="/api/Lookup/GetDescription"
                            onChange={handleChange}
                            required
                            errorText={validation.invoiceDescription}
                            onBlur={handleValidation}
                        />
                      : <div>Loading....</div>
                    }
                </div>
            </div>
            <div className="row p-10px item-list invoice-box">
                <table className='table'>
                    <tr className='text-center'>
                        <th>S.No.</th>
                        <th>Particulers</th>
                        <th>Details</th>
                        <th>SAC Code</th>
                        <th>Amount</th>
                        <th>GST</th>
                        <th>GST Amount</th>
                        <th>Total (₹)</th>
                    </tr>
                    {items?.map((item, idx) => (
                            <InvoiceItem 
                                item={item} 
                                idx={idx}  
                                notify={notify}
                                stateId={invoice.stateId} 
                                particulers={particulers}
                            />
                        ))}
                </table>
            </div>
            <div className="invalid-feedback">{validation.item}</div>
            <div className="row p-10px mb-3 pt-4">
                <div className="col-md-6">
                    <button 
                    type="button" 
                    className="form-control btn btn-sm btn-primary-custom" 
                    onClick={() => setItems(items.concat(defaultItems()))}>Add Item</button>
                </div>
                <div className="col-md-6">
                    <button 
                    type="button" 
                    className="form-control btn btn-sm btn-primary-custom" 
                    onClick={handleSubmit}>Invoice Preview</button>
                </div>
            </div>
            </div>
        </div> 
    </div></>
    <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
    />
    </>
    );
}

export default NewInvoice;
